/* ==================================================================================================================
 * OpenGoSim Bluebell: app/shared/services/rest.service.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { ClearAllStoresAction } from 'app/store/app.store.constants';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  timeout = 20000;

  constructor(private http: HttpClient, private store: Store<any>) { }

  del(url: string): Observable<any> {
    url = environment.server + environment.restUrl + url;
    const headers = this.headers();
    return this.http.delete(url, {headers}).pipe(timeout(this.timeout));
  }

  get(url: string, queryParams?: Object): Observable<any> {
    url = environment.server + environment.restUrl + url;
    let params = new HttpParams();
    if (queryParams) {
      Object.entries(queryParams)
        .filter(([key, value]: [string, any]) => !!value)
        .forEach(([key, value]: [string, any]) => params = params.set(key, value));
    }
    const headers = this.headers();
    return this.http.get(url, {headers, params}).pipe(timeout(this.timeout));
  }

  patch(url: string, data: any): Observable<any> {
    url = environment.server + environment.restUrl + url;
    let headers = this.headers();
    headers = headers.append('Content-Type', 'application/json');
    return this.http.patch(url, data, {headers}).pipe(timeout(this.timeout));
  }

  post(url: string, data: any): Observable<any> {
    url = environment.server + environment.restUrl + url;
    const headers = this.headers();
    return this.http.post(url, data, {headers}).pipe(timeout(this.timeout));
  }

  private headers(): HttpHeaders {
    const token: string = localStorage.getItem('jwt-token');
    if (!token) {
      this.store.dispatch(new ClearAllStoresAction());
    }
    return new HttpHeaders({'Authorization': 'Bearer ' + token});
  }
}
